@use "sass:map";
@import 'fonts.scss';
@import "colors.scss";


.invalid-feedback {
  margin-top: 0 !important;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.form-horizontal .control-label {
  text-align: left;
}

#upc {
  background-color: #03A9F4 !important;
}

.valid-feedback {
  width: auto;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.icon-button {
  color: $light;
  background-color: $primary;
}

.icon-button:hover {
  color: $light;
  background-color: $secondary;
  //padding: 3px;
}

