@use "sass:map";
@import 'fonts.scss';


$text-white: #FFFFFF;
$text-danger: #FFFFFF;
$text-black: #212121;
$bg-black: #212121;



/* make the customizations */

//blues
$primary: #035277;
$secondary: #4FC3F7;
$disabled: #E5E5E5;

////greens
//$primary: #4B7802;
//$secondary: #85D305;

$bg-light: #F8F9FA;

.icon{
  color: $text-black;
}

.table {
  background-color: $bg-light;
}

.icon-button:hover{
  color:white;
}

.app-header {
  background-color: $bg-black;
  color: $text-white;
}

.btn-primary,
{
  color: #fff;
}

.btn-primary:hover {
  background-color: $secondary !important;
  border-color: $secondary!important;
}

@import "~bootstrap/scss/bootstrap";

.icon--warning{
  color: $warning;
}

.icon--restriction{
  color: #fd7e14;
}

.icon--help{
  color: $secondary;
}

.invalid-feedback {
  background-color: $danger;
  color: $text-white;
}

.icon--busy{
  color: #9E9E9E;
}
.icon-secondary{
  color: white;
  background-color: $secondary;
}



.icon--success{
  color:green;
}


.icon--inverted.icon--disabled{
  color: $disabled;
  background-color: #00000000!important;
}



.icon--disabled{
  color: $light;
  background-color: $disabled !important;
}



.icon--error{
  color: $danger;
}



.icon--info{
  color: $secondary;
}


.icon--state-error{
  color: black;
  background-color:$danger;
}

#upc {
  background-color: #03A9F4 !important;
}

.valid-feedback {
  //background-color: var(--success)!important;
  background-color: $success;
  color: $text-white;
}

.icon-button--stop{
  color: $light;
  background-color:$danger;
}


.icon-button--stop:hover{
  color: $light;
  background-color:$danger;
}

.icon-button-alt{
  color: $text-muted;
  background-color: transparent;
}

.icon-button-alt:hover {
  color: $text-black;
  background-color: transparent;
}

.icon-button {
  color: color("text-white");
  background-color: $primary;
}

.icon-button:hover {
  color: white;
  background-color: $secondary;
}

.workspace .project:hover{
  background-color: white;
}

.workspace .selected {
  background-color: white;
}

.folder-indent
{
  background-color:white;
}
