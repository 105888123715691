html, body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}


.MuiTooltip-tooltip
{
  font-size: 14px!important;
}


.app-header__title
{
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-weight: 500;
}


.h1 {
  font-family: "Roboto", sans-serif;
  font-size: 36px!important;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;

}

.h2 {
  font-family: "Roboto", sans-serif;
  font-size: 26px!important;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;

}

h3, .h3 {
  font-family: "Roboto", sans-serif;
  font-size: 16px!important;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

h4 .h4 {
  font-family: "Roboto", sans-serif;
  font-size: 10px!important;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.modal-title  {
  font-family: "Roboto", sans-serif;
  font-size: 26px!important;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}

table {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
}



dt,
dd,
label {
  font-size: 16px;
  line-height: 16px;
}

dt,
label,
thead,th {
  font-family: 'Roboto', sans-serif;
  font-weight: 500!important;
  font-style: normal;
}

dd{
  font-weight: 400!important;
  font-family: 'Roboto Condensed', sans-serif;
}

.form-horizontal .control-label {
  text-align: left;
}