@import "colors";


//td{
//  border: 1px solid crimson!important;
//}


html, body{
  height: 100%;
  width: 100%;
}

.wrapper{
  position: relative;
}

.app-header {
  position: sticky;
  top: 0px;
  z-index: 100;
}

.row {
    margin: 0;
}


#mainContain
{
  float: left;
  
}

#workspaceContainer
{
    float: right;
    margin-left: 8px;
}
.product-definition {
    position: absolute;
    top: 0px;
    right: 0px;
}

.modal-hidden {
  display: none;
}

.modal-block {
  display: flex;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #21212120;
  border-radius: 5px;

}

.modal-bod {
  position: absolute;
  top: 450px;
  left : 20%;
  width: 60%;
}

.modal-message{
  justify-content: center;
  padding: 8px;
  text-align: center;
  font-size: 12pt;
  background-color: $bg-light;
  border: 1px solid #000033;
  border-radius: 5px;
}

.app-header img {
  padding-right: 8px;
  height: 40px;
}

.app-header__title {
  height: 60px;
  display: inline;
}


.app-header__user {
  text-align: right;
}

.ruler {
  height: 20px;
}

.ruler>div {
  text-align: center;
}

.spacer {
  margin-right: 12px !important;
}


.app {
  background-color: #000066;
  min-width: 1000px;
}

td {
  text-align: left;
}

td.date {
  text-align: center;
}

td.numeric {
    text-align: right;
}

td.numeric-fixed {
    text-align: right;
    width: 100px !important;
}

td.full-width {
  width: 100%;
}

td.iconic {
  text-align: center;
  width: 24px;
}

/*th.iconic:last-child,
th.iconic:nth-last-child(2),
td.iconic:nth-last-child(2),*/
td.iconic:last-child {
    text-align: right;
}

td.center {
    text-align: center;
}

.product-track-list td.status-iconic,
td.status-iconic {
  text-align: center;
  vertical-align: bottom !important;
  width: 24px;
}

tr {
  height: 32px;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
}

.truncate:hover {
  display: flow;
  white-space: normal;
  text-overflow: unset;
  overflow: visible;
}

.word-break {
  -ms-word-break: break-all;
  word-break: break-all;
  /* Non standard for webkit */
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}


.dolby-logo {
  height: 24px !important;
}

.product-logo {
    height: 96px !important;
}

.icon {
  width: 24px !important;
  height: 24px !important;
}




.icon-button {
  width: 24px !important;
  height: 24px !important;
  padding: 3px !important;
  border-radius: 3px;

}

.icon-button-text {
  width: 120px !important;
  height: 32px !important;
  padding: 4px;
  font-style: italic;
  text-transform: none !important;
  &:not(.icon--disabled):hover {
    background-color: $secondary !important;
  }
}

$track-map--height: 400px;

.icon-button>.icon--large {
  padding: 4px;
}

.icon--large {
  width: 32px !important;
  height: 32px !important;
  padding: 0px;
}

.icon--x-large {
  width: 48px !important;
  height: 32px !important;
  padding: 0px;
}

.icon--xx-large {
    width: 72px !important;
    height: 72px !important;
    margin-top: -10px;
}

tbody.alert-warning {
  border-top: 1px black solid !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  padding: 20px !important;
}

.track-map-header {
    padding-top: 15px;
    height: 25px !important;
}

.track-map {
    min-height: $track-map--height;
}

.track-map .table-container--sc roll {
  height: $track-map--height;
}

.table-container,
.table-container--scroll {
  min-width: 350px;
  overflow-y: auto;
  max-height: 400px;
  overflow-x: clip;
  margin: 0;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-radius: 3px;
}

tr td:first-child {
  padding-left: 8px !important;
}

tr td:last-child {
  padding-right: 8px !important;
}

.table-container--scroll table {
    min-height: $track-map--height;
    max-height: 400px;
}

.table-container--scroll table tfoot,
.table-container--scroll table thead {
  border-top-style: none !important;
  border-bottom-style: none !important;
}

.audio-resource-list tbody {
  border-top-style: none !important;
  border-bottom-style: none !important;
}

.audio-resource-list th {
  border-bottom: 3px solid $white !important;
}

.audio-resource-list td {
  border-bottom: 1px solid $white !important;
  vertical-align: middle !important;
}

.product-track-list {}

.error-messages {
  min-height: 200px;
}

.error-messages tbody td,
.product-track-list tbody {
  border-top: 3px solid $white !important;
}

.error-messages tbody td,
.product-track-list tbody td {
  border-bottom: 1px solid $white !important;
  vertical-align: middle !important;
}

.filler-row {
  height: 100%;
}

.table-container--scroll table thead {
  position: sticky;
  background: $bg-light !important;
  inset-block-start: 0;
  /* "top" */
}

.table-container--scroll table tfoot {
  position: sticky;
  background: $bg-light !important;
  inset-block-end: 0;
  /* "bottom" */
}



.shift-hidden {
  visibility: hidden;
  display: none;
}

.shift-pressed {}

.shift-pressed .shift-hidden {
  visibility: visible;
  display: revert;
}

.message-box {
  width: auto;
  display: block;
  box-sizing: border-box;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: lightblue;
  position: fixed;
  border-top: black solid;
  padding: 0px 5px 5px 5px;
}

.message-box span {
  cursor: pointer;
}

.message-box button {
  width: auto;
}

.message-box button:active {
  transform: scale(0.95); 
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24); 
}

.message-box .project {
  width: 75%;
  overflow: hidden;
  float: left;
}

.message-box .clipboard {
  right: 0;
  overflow: hidden;
  float: right;
}

.message-box .messages {
  float: left;
}

dt::after {
  content: ":";
}

td {
  padding-right: 4px;
  padding-left: 4px;
}

.workspace tbody >tr> td {
  margin-top: 10px;
  padding: 8px;
  cursor: pointer;
}

.workspace tbody >tr> td:first-child {
  padding-left: 8px!important;
}

.workspace .project-name{
  padding-left: 16px!important;
}

.de-emphasis {
  text-align: right;
  color: #999999;
}

.notes-length-counter {
  text-align: right;
  display: block;
  padding: 2px;
}

.popover-wrapper{
    display: inline-block; 
    height: 8rem!important;
    width: 235px!important;
    overflow: clip;
}

.popover-wrapper div{
  min-height: 1.25em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;  
}

.notes-textarea-div {
  margin-top: 8px;
    width: 100%;
    height: 100px;
    resize: none;
    overflow: auto;
    overflow-wrap: anywhere;
  margin-bottom: 8px;
  white-space: pre-line;
  
}
.notes-textarea
{
    width: 100%;
    height: 100px;
    resize: none;
  
}
.d-flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.track-validation-instruction {
  font-style: italic;
}

#info { 
  height: 40px; 
  position: fixed; 
  bottom:0%;
  width:100%; 
  opacity: 1;
  left: 10px;
}

.cprs-modal {
  max-width: 750px !important;
}

.cprs-modal-body {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

.cprs-modal-checkbox {
  display: flex;
  justify-content: right;
}

.cprsMessage {
    font-style: italic;
    font-weight: bold;
}